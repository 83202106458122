export const menuItems = [{
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        mainCapp: ["AllList", "AllChart", "GetPositions"],
        subItems: [{
                id: 8,
                label: 'menuitems.dashboard.list.show',
                link: '/',
                mainCapp: ["AllList"]

            },
            {
                id: 9,
                label: 'menuitems.dashboard.list.chart',
                link: '/dash/chart',
                mainCapp: ["AllChart", "GetPositions"]
            }
        ]
    },
    {
        id: 10,
        label: 'menuitems.analysis.text',
        icon: 'mdi mdi-home-analytics',
        mainCapp: ["Warning"],
        subItems: [{
                id: 11,
                label: 'menuitems.analysis.list.all',
                link: '/Error-404',
                mainCapp: []
            },
            // {
            //     id: 12,
            //     label: 'menuitems.analysis.list.milk',
            //     link: '/analysis/milk'
            // },
            // {
            //     id: 13,
            //     label: 'menuitems.analysis.list.dry',
            //     link: '/analysis/dry'
            // },
            // {
            //     id: 14,
            //     label: 'menuitems.analysis.list.pregnant',
            //     link: '/analysis/pregnant'
            // },
            // {
            //     id: 15,
            //     label: 'menuitems.analysis.list.nonpregnant',
            //     link: '/analysis/nonpregnant'
            // },
            // {
            //     id: 16,
            //     label: 'menuitems.analysis.list.claf',
            //     link: '/analysis/claf'
            // }
        ]

    },
    {
        id: 17,
        label: 'menuitems.warning.text',
        icon: 'ri-error-warning-line',
        link: '/warning',
        mainCapp: ["Warning"],
        cappList: []
    },
    {
        id: 2,
        label: 'menuitems.users.text',
        icon: ' ri-user-line',
        mainCapp: ["GetUsers", "GetRoles", "GetAccess"],
        subItems: [{
                id: 3,
                label: 'menuitems.users.list.show',
                link: '/users',
                mainCapp: ["GetUsers", "GetRoles"]
            },
            {
                id: 4,
                label: 'menuitems.users.list.role',
                link: '/users/role',
                mainCapp: ["GetRoles", "GetAccess"]
            }
        ]

    },
    {
        id: 5,
        label: 'menuitems.cattels.text',
        icon: 'mdi mdi-cow',
        mainCapp: ["GetCattels", "GetPositions"],
        subItems: [{
                id: 6,
                label: 'menuitems.cattels.list.show',
                link: '/cattel',
                mainCapp: ["GetCattels", "GetPositions"]
            },
            {
                id: 7,
                label: 'menuitems.cattels.list.positions',
                link: '/positions',
                mainCapp: ["GetPositions"]
            }
        ]
    },
]